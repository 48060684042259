import { Router } from "preact-router";
import { useState } from "preact/hooks";

// import blog from "../blog.json";
// import Blog from "../routes/blog";
// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Header from "./header";

const App = () => {
  const [hasHeader, setHasHeader] = useState(true);

  const handleToggleHeader = (isDisplay) => {
    setHasHeader(isDisplay);
  };

  return (
    <div id="app">
      <Header isDisplay={hasHeader} />
      <Router>
        <Home path="/" onToggleHeader={handleToggleHeader} />
        {/* {blog.map(({ href }, index) => (
        <Blog key={index} path={href} index={index} />
      ))} */}
      </Router>
    </div>
  );
};

export default App;
