import clsx from "clsx";
import { Link } from "preact-router/match";

import assetLogoAvif from "../../assets/phibo/logo.avif";
import assetLogoPng from "../../assets/phibo/logo.png";
import assetLogoWebp from "../../assets/phibo/logo.webp";
// import Menu from "../../components/menu";
import i18n from "../../i18n.json";
import style from "./style.css";

const Header = ({ isDisplay }) => {
  return (
    <header class={clsx(style.header, !isDisplay && style.hide)}>
      <Link activeClassName={style.active} href="/">
        <picture>
          <source srcSet={assetLogoAvif} type="image/avif" />
          <source srcSet={assetLogoWebp} type="image/webp" />
          <source srcSet={assetLogoPng} type="image/png" />
          <img
            alt={i18n.a11yLogo}
            class={style.logoMobile}
            src={assetLogoPng}
            width="150"
            height="90"
          />
          <img
            alt={i18n.a11yLogo}
            class={style.logoOther}
            src={assetLogoPng}
            width="200"
            height="120"
          />
        </picture>
      </Link>

      {/* <Menu /> */}
    </header>
  );
};
export default Header;
