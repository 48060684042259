import "./style";

import App from "./components/app";

const addClassToRoot = (className) => {
  document.getElementsByTagName("html")[0].classList.add(className);
};

const checkImgFormatFeature = () => {
  const avif = new Image();
  avif.src =
    "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";

  avif.onload = () => {
    addClassToRoot("avif");
  };

  avif.onerror = () => {
    const webp = new Image();
    webp.src =
      "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";
    webp.onload = () => {
      addClassToRoot("webp");
    };
    webp.onerror = () => {
      addClassToRoot("old");
    };
  };
};

if (typeof window !== "undefined") checkImgFormatFeature();

export default App;
